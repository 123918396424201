$base-color: #51284F;
$secondary-color: #F2F0A1;

$accent-1: #236192;
$accent-2: #28724F;


.App {
    font-family: "Manrope";
    color: $base-color;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Alice";
    margin: 0px;
}

.App-header {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // max-width: 1080px;
    padding: 20px;
    margin: auto;
}

h1 {
    margin: 0;
    color: $base-color;
    font-family: "Alice";

    a {
        text-decoration: none;
    }
}

nav.main {
    display: flex;
    gap: 10px;

    a {
        font-size: 24px;
        font-family: Alice;
        color: $base-color;
        text-decoration: none;

        box-sizing: border-box;

        &:hover {
            color: $accent-1;
        }
    }
}

    a {
        font-family: Alice;
        color: $base-color;
        text-decoration: none;

        &:hover {
            color: $accent-1;
        }
    }

.splash-slogan {

    padding: 20px;

    text-align: center;

    height: 300px;

    .slogan {
        font-family: Alice;
        font-size: 30pt;

        margin-bottom: 15px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

[class$="-block"] {
    padding: 14px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 16px;
        line-height: 2em;
    }

}   

.solid-block {
    background-color: $base-color;
    color: #FFF;
    padding: 14px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;


    .title-text {

        font-size: 48px;
        font-family: Alice;
        text-transform: uppercase;

    }

}

.we-believe {
    img {
        width: 300px;
        float: right;
    }
}

.hollow-block {
    border: 2px solid $base-color;
    border-radius: 2em;

    margin: 30px auto;
    max-width: 1080px;
}

.about {
    padding-top: 20px;
    max-width: 1080px;
    margin: auto;
}

.bios {
    display: flex;
    padding: 16px;
    justify-content: space-evenly;

    .bio {
        max-width: 400px;

        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        .portrait-frame {
            width: 300px;
            height: 300px;
            border-radius: 150px;
            overflow: hidden;
            margin-bottom: 16px;

            img {
                width: 300px;
            }
        }
    }
}

.partner-row {
    padding: 25px;

    .logo-frame {
        height: 150px;
        padding-right: 25px;
        float: left;
    }

    img {
        height: 80%;
        max-width: 250px;
    }
}

.partners {
    max-width: 1080px;
    padding: 20px;
    margin: auto;
}

.contact {
    padding: 20px;

    > h1 {
        margin-bottom: 30px;
    }

    > h2 {
        text-align: center;
    }
}

.individual-contacts {

    display: flex;
    justify-content: space-around;

    padding: 30px 0px;

    > div {
        
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 20px;

        ul {
            border: 2px solid $base-color;
            border-radius: 15px;
            width: 100%;
        }

        iframe {
            border: none;
            height: 450px;
        }
    }
}


main > h1 {
    text-align: center;
}

.tech-logo {
   width: 150px;
   margin: 10px;
}

.logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    padding: 20px;
    border: 2px solid $base-color;
    border-radius: 10px;
    margin: 30px 0px;
    margin-left: 20px;
}

.technologies {
    flex-direction: row;
    justify-content: space-evenly;
}

.hamburger {
    display: none;
    width: 30px;
    opacity: .5;
}

.hs-logo {
    width: 200px;
}


@media only screen and (max-width: 600px) {

    .App main {
        padding-top: 80px;
    }

    .hamburger {
        display: block;
    }

    header {

        position: fixed;
        width: 100vw;

        nav {
            box-sizing: border-box;
            position: absolute;
            transition-property: left;
            transition-duration: 0.2s;

            background-color: white;
            height: 100vh;
            width: 100vw;
        }

        nav.open {
            left: 0;
        }

        nav.closed {
            left: -100vw;
        }

    }

    header > div {
        h1 {
            margin-bottom: 0px;
        }
        box-sizing: border-box;
        display: flex;
        width: 100%;
        background: #FFF;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        
    }

    .App-header {
        flex-direction: column;
        padding: 0px;

        h1 {
            text-align: center;
        }

        nav {
            text-align: center;
            flex-direction: column;
            justify-content: center;
        }
    }

  .partner-row {

    .logo-frame {
       height: 100px;
       padding-right: 10px;
    }

  }
  main.partners h1 {
      margin-top: 1em;
  }

  .technologies {
      flex-direction: column;
  }

  .logos {
      border: 0;
      margin-left: 0;
      margin: 10px 0px;
  }

  .bios {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
  }

  .individual-contacts {
      flex-direction: column;
      width: 100%;
      align-items: center;
  }
  .hide-on-mobile {
      display: none;
  }


}
